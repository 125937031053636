import {
  TYPE_CITATIONS,
  TYPE_EXPERT_CHECK,
  TYPE_GRAMMAR,
  TYPE_PLAGIARISM,
} from '@chegg-wt/core/constants/plusTab'
import { TYPE_CREATE_CITATIONS, TYPE_CHECK_PAPER } from '@chegg-wt/core/constants/card'
import { Config } from '@chegg-wt/core/config'
import * as tabTestIds from '@chegg-wt/core/components/PlusTab/testIds'
import {
  CREATE_CITATIONS_CONTEXT_ID,
  CHECK_PAPER_CONTEXT_ID,
} from '@chegg-wt/core/components/HeaderWithCards/testIds'
import {
  PRICING_TABLE_FREE,
  CHECK,
  PRICING_TABLE_PLUS,
  PRICING_TABLE_STUDY_PACK,
  CITATION_STYLE,
  EXPERT_PAPER_HELP,
  GRAMMAR_CHECKS,
  PLAGIARISM_DETECTION,
  SAVE_CITATIONS,
  ADD_FREE_EXPERIENCE,
  EXPERT_HOMEWORK_HELP,
  PRACTICE_PROBLEMS,
  MATH_SOLVER,
} from '@chegg-wt/core/constants/pricingTable'
import { HomeCopy, PlusTabs, PricingTable } from '@chegg-wt/core/types/core'

export const cards: HomeCopy = {
  header: 'The best papers start with EasyBib®',
  createCitations: {
    imgFilename: 'card-create-citations.svg',
    buttonText: 'Create citations',
    context: {
      value: 'Start a new citation or manage your existing projects.',
      contextTestId: CREATE_CITATIONS_CONTEXT_ID,
    },
    dataTestId: 'create-citations-btn',
    type: TYPE_CREATE_CITATIONS,
  },
  checkPaper: {
    imgFilename: 'card-check-your-paper.svg',
    buttonText: 'Check your paper',
    context: {
      value: 'Scan your paper for plagiarism and grammar errors.',
      contextTestId: CHECK_PAPER_CONTEXT_ID,
    },
    dataTestId: 'check-paper-btn',
    type: TYPE_CHECK_PAPER,
  },
}

export const plusTabs: PlusTabs = {
  header: 'Wipe out writing errors with EasyBib® Plus',
  subHeader:
    'Double check for plagiarism mistakes and advanced grammar errors before you turn in your paper.',
  tabs: [
    {
      label: 'citations',
      imgFilename: 'eb-tabs-citations.png',
      alt: 'cite using APA, MLA, Chicago and more',
      to: '/mla/source',
      header: "Know you're citing correctly",
      context:
        "No matter what citation style you're using (APA, MLA, Chicago, etc.) we'll help you create the right bibliography",
      dataTestId: tabTestIds.CITATIONS_TAB,
      type: TYPE_CITATIONS,
    },
    {
      label: 'plagiarism',
      imgFilename: 'eb-tabs-plagiarism.png',
      alt: 'check for potential plagiarism',
      to: '/grammar-and-plagiarism/',
      header: 'Check for unintentional plagiarism',
      context:
        'Scan your paper the way your teacher would to catch unintentional plagiarism. Then, easily add the right citation',
      dataTestId: tabTestIds.PLAGIARISM_TAB,
      type: TYPE_PLAGIARISM,
    },
    {
      label: 'expert check',
      imgFilename: 'eb-tabs-expert-check.png',
      alt: 'experts will proofread your paper',
      to: '/grammar-and-plagiarism/expert-check/',
      header: 'Strengthen your writing',
      context:
        "Give your paper an in-depth check. Receive feedback within 24 hours from writing experts on your paper's main idea, structure, conclusion, and more.",
      dataTestId: tabTestIds.EXPERT_CHECK_TAB,
      type: TYPE_EXPERT_CHECK,
    },
    {
      label: 'grammar',
      imgFilename: 'eb-tabs-grammar.png',
      alt: 'check for grammar mistakes and writing issues',
      to: '/grammar-and-plagiarism/',
      header: 'Find and fix grammar errors',
      context:
        "Don't give up sweet paper points for small mistakes. Our algorithms flag grammar and writing issues and provide smart suggestions",
      dataTestId: tabTestIds.GRAMMAR_TAB,
      type: TYPE_GRAMMAR,
    },
  ],
}

export const oldPricingTableData: PricingTable = {
  title: 'Choose your online writing help',
  features: [
    { id: 'citationStyle', name: 'Citation styles' },
    { id: 'grammarChecks', name: 'Grammar checks' },
    { id: 'saveCitations', name: 'Save your citations' },
    { id: 'plagiarismDetection', name: 'Plagiarism detection' },
    { id: 'expertPaperHelp', name: 'Expert help for your paper' },
    { id: 'textbookSolutions', name: 'Millions of Textbook Solutions & Expert Q&A' },
    { id: 'practiceProblems', name: 'Practice problems & video solutions**' },
    { id: 'mathSolver', name: 'Instant math solver' },
  ],
  products: [
    {
      id: PRICING_TABLE_FREE,
      name: 'EasyBib® free account',
      price: 'Free',
      features: {
        citationStyle: 'MLA only',
        grammarChecks: 'Only first 5 errors checked',
        saveCitations: CHECK,
      },
    },
    {
      id: PRICING_TABLE_PLUS,
      name: 'EasyBib® Plus 3-day free trial*',
      price: '$9.95/mo.',
      recommended: true,
      features: {
        citationStyle: '7000+ styles including APA & Chicago',
        grammarChecks: 'Unlimited',
        saveCitations: CHECK,
        plagiarismDetection: CHECK,
        expertPaperHelp: '30 papers a month',
      },
    },
    {
      id: PRICING_TABLE_STUDY_PACK,
      name: 'EasyBib® Plus & Chegg® Study Pack',
      price: '$19.95/mo.',
      features: {
        citationStyle: '7000+ styles including APA & Chicago',
        grammarChecks: 'Unlimited',
        saveCitations: CHECK,
        plagiarismDetection: CHECK,
        expertPaperHelp: 'Unlimited',
        textbookSolutions: CHECK,
        practiceProblems: CHECK,
        mathSolver: CHECK,
      },
    },
  ],
  termsAndConditionsUrl: Config.trialTermsAndConditionsUrl,
  studyPackUrl: 'https://www.chegg.com/study-pack/?intcid=wt.EasyBib.pricetablecta',
}

export const newPricingTableData: PricingTable = {
  features: [
    { id: CITATION_STYLE, name: 'Citation style' },
    { id: ADD_FREE_EXPERIENCE, name: 'Ad-free experience' },
    { id: PLAGIARISM_DETECTION, name: 'Plagiarism detection' },
    { id: EXPERT_PAPER_HELP, name: 'Expert help with your papers' },
    { id: GRAMMAR_CHECKS, name: 'Unlimited grammar check' },
    { id: SAVE_CITATIONS, name: 'Save citations' },
    { id: EXPERT_HOMEWORK_HELP, name: 'Expert help with your homework' },
    { id: PRACTICE_PROBLEMS, name: 'Practice problems & video solutions**' },
    { id: MATH_SOLVER, name: 'Instant math solver' },
  ],
  products: [
    {
      id: PRICING_TABLE_FREE,
      name: 'Free account',
      price: 'Free',
      features: {
        citationStyle: 'MLA only',
        grammarChecks: CHECK,
        saveCitations: 'Only for 7 days',
      },
    },
    {
      id: PRICING_TABLE_PLUS,
      name: 'EasyBib® Plus 3-day free trial*',
      price: '$9.95/mo.',
      recommended: true,
      features: {
        citationStyle: 'MLA, APA, Chicago, Harvard & 7,000 more',
        grammarChecks: CHECK,
        saveCitations: CHECK,
        plagiarismDetection: CHECK,
        adFreeExperience: CHECK,
        expertPaperHelp: '30 papers / month',
      },
    },
    {
      id: PRICING_TABLE_STUDY_PACK,
      name: 'EasyBib® Plus & Chegg® Study Pack',
      price: '$19.95/mo.',
      features: {
        citationStyle: 'MLA, APA, Chicago, Harvard & 7,000 more',
        grammarChecks: CHECK,
        saveCitations: CHECK,
        plagiarismDetection: CHECK,
        expertPaperHelp: 'Unlimited',
        expertHomeworkHelp: CHECK,
        practiceProblems: CHECK,
        mathSolver: CHECK,
        adFreeExperience: CHECK,
      },
    },
  ],
  termsAndConditionsUrl: Config.trialTermsAndConditionsUrl,
  studyPackUrl: 'https://www.chegg.com/study-pack/?intcid=wt.EasyBib.pricetablecta',
}
