import React from 'react'
import { useSelector } from 'react-redux'
import { getLvpLayout } from '@chegg-wt/core/components/Layouts/LargeVideoPlayerLayout'
import { NextPageWithLayout } from '@chegg-wt/core/types/layout'
import Home, { HomeTop } from '@chegg-wt/core/components/Layouts/Home'
import SEOTitle from '@chegg-wt/core/components/SEOTitle'
import useUserRole from '@chegg-wt/core/hooks/useUserRole'
import useRedirect from '@chegg-wt/core/hooks/useRedirect'
import { useRoutesContext } from '@chegg-wt/core/components/RoutesContext'
import { UserRoles } from '@chegg-wt/core/constants/user'
import WritingAssistantHome from '@chegg-wt/core/components/Layouts/WritingAssistantHome'
import { useOnUnloadTimeOnPage } from '@chegg/time-on-page'
import { isWritingAssistantHomeFlagEnabledSelector } from '@chegg-wt/core/state/paraphraser/selectors'
import { cards, plusTabs, oldPricingTableData, newPricingTableData } from '../copy/Home'
import resources from '../copy/resources'

const resourceTitle = 'EasyBib® Guides & Resources'
const HomePage: NextPageWithLayout = () => {
  const userRole = useUserRole()
  const isWritingAssistantHomeEnabled = useSelector(
    isWritingAssistantHomeFlagEnabledSelector
  )
  const { toDashboard } = useRoutesContext()
  useRedirect({
    shouldRedirect: userRole === UserRoles.PRO_USER,
    redirectRoute: toDashboard(),
  })
  useOnUnloadTimeOnPage()

  return (
    <>
      <SEOTitle title="EasyBib®: Free Bibliography Generator - MLA, APA, Chicago citation styles" />
      {isWritingAssistantHomeEnabled !== null &&
        (isWritingAssistantHomeEnabled ? (
          <WritingAssistantHome copy={{ resources }} />
        ) : (
          <Home
            copy={{
              oldPricingTableData,
              newPricingTableData,
              plusTabs,
              resources,
              resourceTitle,
            }}
          />
        ))}
    </>
  )
}

HomePage.getPageLayout = (page) =>
  getLvpLayout(page, () => <HomeTop cards={cards} />, false, true, true)

export default HomePage
