import { Config } from '@chegg-wt/core/config'
import { Resource } from '@chegg-wt/core/types/core'

const resources: Resource[] = [
  {
    header: 'MLA Format Guide',
    summary:
      'This is the total package when it comes to MLA format. Our easy to read guides come complete with examples and step-by-step instructions to format your full and in-text citations, paper, and works cited in MLA style. There’s even information on annotated bibliographies.',
    url: `${Config.baseUrl}/guides/citation-guides/mla-format/`,
    seoLinks: [
      {
        children: 'Works Cited',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/how-to-format-a-mla-works-cited-list/`,
      },
      {
        children: 'In-Text Citations',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/in-text-citations/`,
      },
      {
        children: 'Bibliography',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/bibliography-format-mla/`,
      },
      {
        children: 'Annotated Bibliography',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/annotated-bibliography-format-mla/`,
      },
      {
        children: 'Website',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/how-to-cite-a-website-mla/`,
      },
      {
        children: 'Book',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/how-to-cite-a-book-mla/`,
      },
      {
        children: 'Journal',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/how-to-cite-a-journal-mla/`,
      },
      {
        children: 'YouTube',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/how-to-cite-video-youtube-mla/`,
      },
      {
        children: 'View all MLA Citation Examples',
        href: `${Config.baseUrl}/guides/citation-guides/mla-format/mla-citation/`,
      },
    ],
    dataTestId: 'mla-guide-link',
  },
  {
    header: 'APA Format Guide',
    summary:
      'Get the facts on citing and writing in APA format with our comprehensive guides. Formatting instructions, in-text citation and reference examples, and sample papers provide you with the tools you need to style your paper in APA.',
    url: `${Config.baseUrl}/guides/citation-guides/apa-format/`,
    seoLinks: [
      {
        children: 'Reference Page',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/how-to-format-an-apa-reference-page/`,
      },
      {
        children: 'In-Text Citations',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/apa-in-text-citation/`,
      },
      {
        children: 'Annotated Bibliography',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/annotated-bibliography-format-apa/`,
      },
      {
        children: 'Website',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/how-to-cite-a-website-apa/`,
      },
      {
        children: 'Books',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/how-to-cite-a-book-apa/`,
      },
      {
        children: 'Journal',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/how-to-cite-a-journal-apa/`,
      },
      {
        children: 'YouTube',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/youtube-video/`,
      },
      {
        children: 'View all APA citation Examples',
        href: `${Config.baseUrl}/guides/citation-guides/apa-format/apa-citation/`,
      },
    ],
    dataTestId: 'apa-guide-link',
  },
  {
    header: 'Chicago Format Guide',
    summary:
      'Looking to format your paper in Chicago style and not sure where to start? Our guide provides everything you need! Learn the basics and fundamentals to creating references and footnotes in Chicago format. With numerous examples and visuals, you’ll be citing in Chicago style in no time.',
    url: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/`,
    seoLinks: [
      {
        children: 'Footnotes',
        href: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/footnotes/`,
      },
      {
        children: 'Website',
        href: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/how-to-cite-a-website-chicago-turabian/`,
      },
      {
        children: 'Book',
        href: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/how-to-cite-a-book-chicago-turabian/`,
      },
      {
        children: 'Journal',
        href: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/how-to-cite-a-journal-chicago-turabian/`,
      },
    ],
    dataTestId: 'chi-guide-link',
  },
  {
    header: 'Harvard Referencing Guide',
    summary:
      'Learn the requirements to properly reference your paper in Harvard style. The guides we have provide the basics and fundamentals to give credit to the sources used in your work.',
    url: `${Config.baseUrl}/guides/citation-guides/harvard-referencing/`,
    seoLinks: [
      {
        children: 'In-Text Citations',
        href: `${Config.baseUrl}/guides/citation-guides/harvard-referencing/in-text-citations-harvard-referencing/`,
      },
      {
        children: 'Books',
        href: `${Config.baseUrl}/guides/citation-guides/harvard-referencing/how-to-reference-books-harvard/`,
      },
      {
        children: 'Article',
        href: `${Config.baseUrl}/guides/citation-guides/harvard-referencing/how-to-reference-an-article-harvard/`,
      },
      {
        children: 'YouTube',
        href: `${Config.baseUrl}/guides/citation-guides/harvard-referencing/how-to-reference-youtube-video-harvard/`,
      },
      {
        children: 'View all Harvard Referencing Examples',
        href: `${Config.baseUrl}/guides/citation-guides/harvard-referencing/harvard-referencing-style-examples/`,
      },
    ],
    dataTestId: 'harvard-guide-link',
  },
  {
    header: 'Check Your Paper',
    summary:
      'Avoid common grammar mistakes and unintentional plagiarism with our essay checker. Receive personalized feedback to help identify citations that may be missing, and help improve your sentence structure, punctuation, and more to turn in an error-free paper.',
    url: `${Config.baseUrl}/grammar-and-plagiarism/`,
    seoLinks: [
      {
        children: 'Grammar Check',
        href: `${Config.baseUrl}/grammar-and-plagiarism/grammar-checker/`,
      },
      {
        children: 'Plagiarism Checker',
        href: `${Config.baseUrl}/grammar-and-plagiarism/plagiarism-checker/`,
      },
      {
        children: 'Spell Check',
        href: `${Config.baseUrl}/grammar-and-plagiarism/spell-checker/`,
      },
    ],
    dataTestId: 'check-link',
  },
  {
    header: 'Learn From Our Innovative Blog',
    summary:
      'Our blog features current and innovative topics to keep you up to speed on citing and writing. Whether you’re an educator, student, or someone who lives and breathes citations (it’s not as uncommon as you might think!), our blog features new and exciting articles to discover and learn from.',
    url: `${Config.baseUrl}/guides/blog/`,
    dataTestId: 'blog-link',
  },
  {
    header: 'Looking for Other Tools and Resources?',
    summary:
      'Our Writing Center is jam-packed with tons of exciting resources. Videos, infographics, research guides, and many other citation-related resources are found here. Check it out to find what you need to succeed!',
    url: `${Config.baseUrl}/guides/`,
    dataTestId: 'grammar-guides-link',
  },
]

export const sharedResources: Resource[] = [
  {
    header: 'What You Need to Know About MLA Formatting',
    summary:
      "Writing a paper soon? If your assignment requires the use of Modern Language Association (MLA) style, then you're in luck! EasyBib® has tools to help you create citations for over 50 source types in this style, as well as a guide to show you how an MLA paper should be formatted. Review the guide to learn how to format a paper's title page, paragraphs, margins, quotations, abbreviations, numbers, tables, and more! There are even tips on editing, as well as on the type of paper you choose to print your paper on—yes, it's that comprehensive!",
    url: `${Config.baseUrl}/guides/citation-guides/mla-format/`,
    dataTestId: 'mla-guide-link',
  },
  {
    header: 'A Handy Guide for Using APA Format',
    summary:
      "Ever wonder how to cite a book with no author in APA style? Do you know how graphics should be formatted in a paper? Thanks to our EasyBib® guide on citing and formatting in American Psychological Association (APA) style, you don't have to guess anymore! We break down the guidelines for you into separate, digestible chunks of information that range from the ways to present headers, to use of abbreviations, to how to format titles for citations. There are also several helpful citation examples for you to review. Read up and start learning today!",
    url: `${Config.baseUrl}/guides/citation-guides/apa-format/`,
    dataTestId: 'apa-guide-link',
  },
  {
    header: 'Chicago Style Simplified',
    summary:
      'Jump start your knowledge of the Chicago Manual of Style (or Turabian style) with our structured EasyBib® guides. Each one will teach you the structure of a Chicago-style citation, followed by a real-life citation example for you to examine. Begin with our "“"Quick Guide" on citing common source types (books, magazines, newspapers, and websites). Then, discover why we have footnotes and how they work, or choose a "How to Cite" guide based on the source type you\'re using (e.g. photo, film, tweet, journal, blog, video on YouTube, conference paper, etc.). You\'re in charge of your own learning path!',
    url: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/`,
    dataTestId: 'chi-guide-link',
  },
  {
    header: 'Student & Teacher Blog for Better Papers',
    summary:
      "Keep your citing skills current and your writing skills fresh by reading our weekly EasyBib® Blog. You'll find articles about citing interesting source types (know how to cite a meme?), the latest updates to our tools and services, writing tips and tricks, and more! Aside from content that students (or any writer) could benefit from, we also feature posts written by educators, for educators! They discuss writing and information literacy pedagogy, present resource recommendation lists, and generally share their experience and knowledge.",
    url: `${Config.baseUrl}/guides/blog/`,
    dataTestId: 'blog-link',
  },
  {
    header: 'Discover the EasyBib® Writing Center',
    summary:
      "Visit our writing center and explore our library of engaging guides, articles, videos, lesson plans, infographics, and other informative resources on citing, writing, and the research process. Best of all, it's free, and you can visit it anytime you need assistance. Need it now? Simply go to our homepage and input keywords based on your topic into the search bar. From there, any relevant guides will be listed with a brief description, allowing you to make an educated selection. Click on a result that fits your needs and begin reading! Easy peasy.",
    url: `${Config.baseUrl}/guides/`,
    dataTestId: 'writing-center-link',
  },
]

export default resources
